/* deposite Dialog */
.deposite_dialog > div:nth-child(3) > div {
    background: rgb(24 27 31);
    border-radius: 20px;
    padding: 40px;
    width: 550px;
}

.deposite_dialog .deposite_dialog_content > h2 {
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    font-size: 30px;
    line-height: 35px;
    color: #FFFFFF;
    margin: 0;
    text-align: center;
}

.deposite_dialog .deposite_section1 {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}

.deposite_dialog .deposite_section1 > span {
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
    text-align: left;
    margin-bottom: 15px;
}

.deposite_dialog .ibc_transfer_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.deposite_dialog .ibc_transfer_section > div {
    background: rgba(33, 37, 42, 0.5);
    border: 1px solid #292929;
    box-sizing: border-box;
    border-radius: 10px;
    width: 200px;
    text-align: left;
    padding: 5px 10px;
}

.deposite_dialog .ibc_transfer_section > div > span {
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #828282;
}

.deposite_dialog .ibc_transfer_section > div .hash_text {
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    font-feature-settings: 'zero' on;
    color: #FFFFFF;
    max-width: 160px;
}

.deposite_dialog .deposite_section2 {
    margin-top: 50px;
}

.deposite_dialog .deposite_section2 .deposite_section2_header {
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
    text-align: left;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.deposite_dialog .deposite_section2 .deposite_section2_header .balance {
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #1DA1F2;
}

.deposite_section2_header .balance {
    display: flex;
}

.deposite_section2_header .balance .spinner > div {
    background: #1DA1F2;
}

.deposite_dialog .deposite_section2 .amount_field {
    width: 100%;
}

.deposite_dialog .deposite_section2 .amount_field > div > div:nth-child(1) {
    margin: 0;
}

.deposite_dialog .deposite_section2 .amount_field .token_start {
    font-family: 'Work Sans', sans-serif;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
    margin-left: 10px;
    border-right: 1px solid #3f5a6f;
    padding-right: 20px;
}

.deposite_dialog .deposite_section2 .amount_field .amount_end {
    font-family: 'Work Sans', sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #1DA1F2;
    cursor: pointer;
}

.deposite_dialog .deposite_section2 .amount_field input {
    font-family: 'Work Sans', sans-serif;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
}

.deposite_dialog .deposite_button {
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    background: linear-gradient(91.79deg, #FAC01E 3.15%, #FC762A 85.66%);
    border-radius: 5px;
    margin-top: 50px;
    padding: 12px 20px;
    text-transform: capitalize;
    width: 100%;
}

.deposite_dialog .deposite_button:disabled,
.activity_table .deposite_value:disabled {
    opacity: 0.5;
}

.deposite_dialog .deposite_button > span {
    background: linear-gradient(180deg, #0E151B 0%, #000000 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* confirm_ibc_dialog */
.confirm_ibc_dialog > h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #E7E7E7;
    margin: 0 0 30px;
}

.confirm_ibc_dialog > div .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.confirm_ibc_dialog > div .row .label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #B7B7B7;
}

.confirm_ibc_dialog > div .row .value {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #F2F2F2;
}

.confirm_ibc_dialog > div .row .token_info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.confirm_ibc_dialog > div .row .token_info img {
    margin-right: 10px;
    width: 20px;
}

.confirm_ibc_dialog > div .row .hash_text.address {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #F2F2F2;
    max-width: 200px;
}


.confirm_ibc_dialog .confirm_actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.confirm_ibc_dialog .confirm_actions > button {
    border-radius: 5px;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    width: 49%;
    height: 50px;
    text-transform: uppercase;
    margin-top: 50px;
}

.deposite_dialog > div:nth-child(3) > div .ledger_note.warning {
    color: red;
    margin: 40px 0;
}

.deposite_dialog .confirm_actions .cancel_button {
    position: relative;
    text-transform: capitalize;
    display: block;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-right: 20px;
    z-index: 0;
}

.deposite_dialog .confirm_actions .cancel_button p {
    background: linear-gradient(91.79deg, #fac01e 3.15%, #fc762a 85.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.deposite_dialog .confirm_actions .cancel_button:before {
    content: "";
    position: absolute;
    background: #181b1f;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 5px;
}

.deposite_dialog .confirm_actions .cancel_button:after {
    content: "";
    background: linear-gradient(91.79deg, #fac01e 3.15%, #fc762a 85.66%);
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -2;
    border-radius: 5px;
}

.deposite_dialog .confirm_actions .confirm_button {
    padding: 14px 12px;
    background: linear-gradient(91.79deg, #FAC01E 3.15%, #FC762A 85.66%);
    border-radius: 5px;
    text-transform: capitalize;
}

.deposite_dialog .confirm_actions .confirm_button > p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    background: linear-gradient(180deg, #0E151B 0%, #000000 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.deposite_dialog > div:nth-child(3) > div .ledger_note {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #B7B7B7;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
}

.deposite_dialog .success_deposit {
    font-family: 'Quantico', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    color: #E7E7E7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.deposite_dialog.success_deposit_dialog > div:nth-child(3) > div {
    background-image: url("../../../assets/success_image.png");
    height: 600px;
    width: 600px;
    background-size: contain;
}

.deposite_dialog .success_deposit > p {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    background: linear-gradient(91.79deg, #FAC01E 3.15%, #FC762A 85.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 10px;
}

.deposite_dialog .success_deposit > span {
    margin-top: 30px;
}

.deposite_dialog .success_deposit > div {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #696969;
    border: 1px solid #696969;
    box-sizing: border-box;
    border-radius: 100px;
    padding: 5px 20px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
}

.deposite_dialog .success_deposit > div > span {
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 90%;
}

@media (max-width: 425px) {
    .deposite_dialog > div:nth-child(3) > div {
        padding: 20px;
    }

    .deposite_dialog .ibc_transfer_section {
        flex-direction: column;
    }

    .deposite_dialog .ibc_transfer_section > div {
        width: 100%;
    }

    .deposite_dialog .ibc_transfer_section > img {
        margin: 10px 0;
    }

    .deposite_dialog .ibc_transfer_section > div .hash_text {
        font-size: 16px;
    }

    .deposite_dialog .deposite_section2 .deposite_section2_header {
        flex-direction: column;
        align-items: flex-start;
    }
}
