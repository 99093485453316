.home {
    background: #191D21;
    padding-bottom: 50px;
}

.section1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.section1 > img {
    width: 100%;
}

.section2 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section2 .spinner {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section2 > h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #FFFFFF;
}

.section2 > p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #FFFFFF;
    max-width: 800px;
}

.section2 .social_links {
    margin-top: 20px;
}

.section3 .claim_fee_grant_card {
    background: rgba(35, 40, 44, 0.4);
    border: 1px solid #22272B;
    border-radius: 5px;
    padding: 30px;
}

.section3 .claim_fee_grant_card .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.section3 .claim_fee_grant_card .header h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    margin: 0;
}

.section3 .claim_fee_grant_card .header a {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    background: linear-gradient(90deg, #FAC01E 0%, #FC762A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
}

.section3 .claim_fee_grant_card .content1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #DFDFDF;
    text-align: left;
    margin-top: 20px;
}

.section3 .claim_fee_grant_card .content2 {
    text-align: left;
    margin-top: 10px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #DFDFDF;
}

.section3 .claim_fee_grant_card .content2 > span {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    background: linear-gradient(90deg, #FAC01E 0%, #FC762A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section3 .claim_fee_grant_card button {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    background: linear-gradient(90deg, #FAC01E 0%, #FC762A 100%);
    border-radius: 5px;
    text-transform: capitalize;
    margin-top: 30px;
    width: 100%;
    padding: 12px;
}

.section3 .claim_fee_grant_card button:disabled {
    opacity: 0.4;
}


.claim_fee_grant_card .allowance_amount {
    margin-top: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.claim_fee_grant_card .allowance_amount > div {
    font-family: 'Ubuntu', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    color: #A4A4A4;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.claim_fee_grant_card .allowance_amount > div > p {
    font-family: 'Ubuntu', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    color: #FFFFFF;
    text-transform: capitalize;
    margin-left: 5px;
}

.section3 {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.section3 > div:first-child {
    margin-right: 20px;
}

.section3 > div:first-child .chain_id {
    display: none;
}

.section3 > div {
    width: 600px;
    display: flex;
    flex-direction: column;
}

.section3 > div > div {
    flex: 1;
}

.section3 .mint_nft_card {
    background: rgba(35, 40, 44, 0.4);
    border: 1px solid #22272B;
    border-radius: 5px;
    padding: 30px;
}

.section3 .mint_nft_card .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.section3 .mint_nft_card .header > h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    margin: 0;
}

.section3 .mint_nft_card .header .spinner {
    margin-right: unset;
}

.section3 .mint_nft_card .header > div .whitelist_drop {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #22272B;
    background: linear-gradient(90deg, #FAC01E 0%, #FC762A 100%);
    border-radius: 10px;
    padding: 5px 10px;
    margin-right: 10px;
}

.section3 .mint_nft_card .header > div .status {
    background: #27AE60;
    border-radius: 10px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    padding: 5px 10px;
}

.section3 .mint_nft_card .header > div .status.in_active {
    background: red;
}

.section3 .mint_nft_card .content {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #DFDFDF;
    text-align: left;
    margin: 20px 0;
}

.section3 .mint_nft_card .content_header {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #DFDFDF;
    min-width: 35%;
    text-align: left;
}

.section3 .mint_nft_card .left_section {
    min-width: 35%;
}

.section3 .mint_nft_card .left_section .content_header {
    min-width: unset;
}

.section3 .mint_nft_card .left_section .quota {
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    background: linear-gradient(90deg, #FAC01E 0%, #FC762A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 5px;
}

.section3 .mint_nft_card .section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    width: 100%;
}

.section3 .mint_nft_card .text_field > div,
.section3 .mint_nft_card .text_field > div:before {
    border: unset;
}

.section3 .mint_nft_card .text_field > div div {
    display: flex;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    font-feature-settings: 'zero' on;
    color: #FFFFFF;
}

.section3 .mint_nft_card .quantity_number {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 65%;
}

.section3 .mint_nft_card .quantity_number .default_quantity {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    border: 1px solid #3B444B;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 2px;
    width: 28px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    cursor: pointer;
    min-width: unset;
}

.section3 .mint_nft_card .quantity_number .default_quantity:disabled {
    opacity: 0.4;
}

.section3 .mint_nft_card .quantity_number .active_quantity {
    background: linear-gradient(90deg, #FAC01E 0%, #FC762A 100%);
    border-radius: 5px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
}

.section3 .mint_nft_card .total_cal {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    font-feature-settings: 'zero' on;
    color: #FFFFFF;
}

.section3 .mint_nft_card .total_cal > span {
    margin: 0 5px;
}

.section3 .mint_nft_card .total_cal > div {
    background: #191D21;
    border: 1px solid #22272B;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    font-feature-settings: 'zero' on;
    color: #B8B8B8;
}

.section3 .mint_nft_card .total_cal > div span {
    margin-left: 10px;
}

.section3 .mint_nft_card .total_cal img {
    width: 20px;
    height: 20px;
}

.section3 .mint_nft_card .total_cal p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    font-feature-settings: 'zero' on;
    color: #FFFFFF;
}

.section3 .mint_nft_card .total_cal .total_amount {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    font-feature-settings: 'zero' on;
    background: linear-gradient(91.79deg, #FAC01E 3.15%, #FC762A 85.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0 6px;
}

.section3 .mint_nft_card .mint_button {
    background: linear-gradient(90deg, #FAC01E 0%, #FC762A 100%);
    border-radius: 5px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #000000;
    width: 100%;
    margin: 10px 0;
    padding: 12px;
}

.section3 .mint_nft_card .mint_button:disabled {
    opacity: 0.4;
}

.section3 .mint_nft_card .mint_left {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    background: linear-gradient(90deg, #FAC01E 0%, #FC762A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section3 .deposit_card {
    background: rgba(35, 40, 44, 0.4);
    border: 1px solid #22272B;
    border-radius: 5px;
    padding: 30px;
    margin-top: 20px;
}

.section3 .deposit_card .header h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    margin: 0;
    text-align: left;
}

.section3 .deposit_card .content {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #DFDFDF;
    margin-top: 20px;
    text-align: left;
}

.section3 .deposit_card .amount {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    font-feature-settings: 'zero' on;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.section3 .deposit_card .amount .spinner {
    margin: unset;
}

.section3 .deposit_card .amount p {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    font-feature-settings: 'zero' on;
    background: linear-gradient(91.79deg, #FAC01E 3.15%, #FC762A 85.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section3 .deposit_card .amount > span {
    margin: 20px 10px;
}

.section3 .deposit_card .amount p > span {
    font-size: 20px;
}

.section3 .deposit_card .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.section3 .deposit_card .actions button:nth-child(2) {
    margin: 0 10px;
}

.section3 .deposit_card .actions button {
    background: linear-gradient(90deg, #FAC01E 0%, #FC762A 100%);
    border-radius: 5px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    width: 50%;
    padding: 12px;
    text-transform: capitalize;
}

.section3 .deposit_card .actions button:disabled {
    opacity: 0.4;
}

.section3 .chain_id {
    background: rgba(35, 40, 44, 0.4);
    border: 1px solid #22272B;
    border-radius: 5px;
    padding: 30px;
    margin-bottom: 20px;
    position: relative;
}

.section3 .chain_id .spinner {
    margin: 20px auto;
}

.section3 .chain_id .keplr_button {
    margin-top: 0;
}

.section3 .chain_id .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.section3 .chain_id .header > div {
    display: flex;
}

.section3 .chain_id h3 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    font-feature-settings: 'zero' on;
    color: #FFFFFF;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section3 .chain_id h3 img {
    margin-right: 5px;
}

.section3 .chain_id p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    font-feature-settings: 'zero' on;
    color: #8E96AB;
    margin-left: 10px;
}

.section3 .chain_id .address_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.section3 .chain_id .address_div .address_content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 0;
}

.section3 .chain_id .copy_button.copy_icon_button {
    margin-left: 10px;
}

.section3 .chain_id .address_div .address_content,
.section3 .chain_id .address_div .hash_text,
.section3 .chain_id .address_div .hash_text > p {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    font-feature-settings: 'zero' on;
    color: #FFFFFF;
}

.section3 .chain_id .address_div .hash_text {
    max-width: 200px;
}

.section3 .chain_id .whitelisted {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    background: linear-gradient(90deg, #FAC01E 0%, #FC762A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: left;
    margin-top: 20px;
}

.section3 .chain_id .buttons_section {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section3 .chain_id .buttons_section a {
    margin-right: 20px;
    text-decoration: unset;
}

.section3 .chain_id .buttons_section button img {
    margin-left: 10px;
    margin-top: 1px;
}

.section3 .mint_nft_card .price_per_nft_textfield input {
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    font-feature-settings: 'zero' on;
    opacity: 1;
    background: linear-gradient(90deg, #FAC01E 0%, #FC762A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section3 .claim_fee_grant_card .header .mobile_view {
    display: none;
}

.section3 .disconnect_button:hover {
    background: #EB5757;
}

.section3 .disconnect_button svg {
    stroke: #EB5757;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.section3 .disconnect_button:hover svg {
    stroke: #22272b;
}

.section3 .mint_info {
    display: flex;
    align-items: center;
    justify-content: center;
}

.section3 .mint_info .divider {
    background: #4F4F4F;
    width: 2px;
    height: 16px;
    margin: 0 10px;
    display: block;
}

.section3 .counter {
    display: flex;
    align-items: center;
}

.section3 .counter p {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    background: linear-gradient(90deg, #FAC01E 0%, #FC762A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section3 .counter span {
    color: #56CCF2;
}

.section3 .queue_button {
    background: #EB5757;
    border-radius: 5px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #F2F2F2;
    padding: 6px 16px;
    text-transform: unset;
    box-shadow: 0 0 0 5px #eb5757;
    animation: tada 1s infinite;
}

.section3 .queue_button:hover {
    background: #EB5757;
    animation: unset;
}

.home .footer {
    margin-top: 50px;
}

.home .footer a {
    text-decoration: unset;
}

@media (max-width: 1024px) {
    .section3 {
        flex-direction: column;
        padding: 0 50px;
    }

    .section3 > div {
        width: 100%;
    }

    .section3 > div:first-child {
        margin: 0 0 20px;
    }

    .section3 > div:first-child .chain_id {
        display: block;
    }

    .section3 > div:nth-child(2) .chain_id {
        display: none;
    }

    .section2 {
        padding: 0 50px;
    }

    .section2 > p {
        width: 80%;
    }
}

@media (max-width: 768px) {
    .section2,
    .section3 {
        padding: 0 30px;
    }

    .section2 > p {
        width: 100%;
    }
}

@media (max-width: 425px) {
    .section2 {
        padding: 0 20px;
    }

    .section2 > h2 {
        margin-bottom: 20px;
    }

    .section2 > p {
        font-size: 15px;
    }

    .section3 {
        padding: 0 20px;
        margin-top: 30px;
    }

    .section3 .deposit_card .amount {
        font-size: 24px;
    }

    .section3 .mint_nft_card .section {
        flex-direction: column;
        align-items: flex-start;
    }

    .section3 .mint_nft_card .quantity_number .default_quantity {
        min-width: 28px;
    }

    .section3 .chain_id,
    .section3 .deposit_card,
    .section3 .mint_nft_card,
    .section3 .claim_fee_grant_card {
        padding: 20px;
    }

    .section3 .claim_fee_grant_card .header span {
        display: none;
    }

    .section3 .claim_fee_grant_card .header .mobile_view {
        display: unset;
    }

    .section1 > img {
        object-fit: cover;
        height: 80px;
    }

    .section3 .chain_id .address_div {
        flex-direction: column;
    }

    .section3 .chain_id .buttons_section {
        flex-direction: column;
    }

    .section3 .chain_id .buttons_section a {
        width: 100%;
        margin: 0 0 10px;
    }

    .section3 .deposit_card .actions button {
        font-size: 12px;
        padding: 12px 6px;
    }
}

@media (max-width: 375px) {
    .section2,
    .section3 {
        padding: 0 10px;
    }

    .section3 .chain_id h3 {
        margin-bottom: 6px;
    }

    .section3 .mint_nft_card .header {
        flex-direction: column;
        justify-content: center;
    }

    .section3 .mint_nft_card .header > h2 {
        margin-bottom: 10px;
    }

    .section3 .mint_nft_card .content {
        text-align: center;
    }

    .section3 .mint_nft_card .total_cal {
        flex-wrap: wrap;
    }

    .home .footer svg {
        width: 80%;
    }
}

@media (max-width: 320px) {
    .claim_fee_grant_card .allowance_amount {
        flex-direction: column;
    }
}
